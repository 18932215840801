.bg-loader-quiz {
    position: fixed;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    z-index: 9998;
    background-color: rgba(255, 255, 255, 0.85);
}

.loader-quiz {
    position: fixed;
    left: 50%;
    top: 45%;
    color: rgba(0, 0, 255, 1);
    z-index: 9999;
}