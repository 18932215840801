.coming-soon {
    position: fixed;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    z-index: 9998;
    background-color: rgba(224, 224, 224, 0.85);
}

.coming-soon-label {
    position: fixed;
    left: 32%;
    top: 20%;
    z-index: 9999;
}
