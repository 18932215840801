.grid-tmpl-home {
  grid-template-columns: 1fr 2fr !important;
}

.icon--size-small {
  line-height: inherit !important;
}

.u-list__item {
  font-size: 17px !important;
}

td.ot-host-td, td.ot-cookies-td, td.ot-cookies-type, td.ot-life-span-td, th.ot-table-header {
  border-style: solid;
  border-width: 1px;
  padding: 7px;
  border-color: rgb(207, 207, 207);
}